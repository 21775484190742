<template>
  <div class="column">
    <div class="col column no-wrap full-width">
      <ErrorsCarousel :errors="currentErrors" />

      <TransferProcessCarousel
        v-if="!store.currentItem"
        ref="stocksCarousel"
        v-model:slide="selectedMovementIndex"
        :input-disabled="carouselInputDisabled"
        @barcode-scan="scanField.scan($event)"
        @input-amount="updateSelectedItemAmount"
        @submit-amount="handleSubmitAmount"
      />

      <TransferChangeTargetStorage
        v-model="changeTargetStorageDialog"
        @changed="handleTargetStorageChanged"
      />
      <template v-if="!changeTargetStorageDialog">
        <div
          v-if="!task"
          class="q-px-lg q-py-sm"
        >
          <BaseScanField
            ref="scanField"
            :hint="t('Scan Product or Storage')"
            :placeholder="t('Product or Storage')"
            :disabled="isScanStorableDisabled"
            no-omni-input-scan
            :search-fn="b => store.searchStorageOrProductPack(b)"
            @scan="handleScan"
            @not-found="handleStorableNotFoundError"
          />
        </div>
        <ScanExpectedStorable
          v-if="store.currentItem"
          ref="scanPackRef"
          :product-pack="store.currentItem.storageUnit.productPack"
          :scanned-amount="store.currentItem.takenAmount"
          :needed-scanned-amount="store.currentItem.plannedAmount"
          :search-fn="store.searchStorableOrStorage"
          class="col justify-center"
          @scan="handleScan"
          @delete-selected="handleDeleteItem"
        />
      </template>
    </div>

    <Teleport to="#teleport-target-buttons-row">
      <ButtonsRow v2>
        <template #default="{ buttonProps }">
          <QBtn
            v-if="store.transfer?.task"
            v-bind="buttonProps"
            icon="mdi-file-tree-outline"
            @click="emit('show-task')"
          >
            {{ t('Plan') }}
          </QBtn>
          <KeyboardToggleButton
            v-bind="buttonProps"
            :disable="carouselInputDisabled"
          />
          <ConfirmsAction
            :confirm-text="t('Yes')"
            :cancel-text="t('No')"
            @confirmed="handleCancel"
          >
            <template #title>
              {{ t('Cancel Transfer?') }}
            </template>
            <template #activator="{ prompt }">
              <QBtn
                v-bind="buttonProps"
                icon="mdi-close-circle-outline"
                @click="prompt"
              >
                {{ t('Cancel') }}
              </QBtn>
            </template>
          </ConfirmsAction>
          <QBtn
            v-if="!task"
            v-bind="buttonProps"
            icon="mdi-check-all"
            :loading="progress.takingAll || progress.completing"
            @click="takeAll()"
          >
            {{ t('Take All') }}
          </QBtn>
          <QBtn
            v-if="!store.currentItem?.storageTo"
            v-bind="buttonProps"
            icon="mdi-swap-horizontal"
            @click="changeTargetStorageDialog = true"
          >
            {{ t('Change Storage') }}
          </QBtn>
          <ConfirmsAction
            :confirm-text="t('Yes')"
            :cancel-text="t('No')"
            :should-prompt="itemsWithErrorsIndexes.length === 0"
            @confirmed="complete"
          >
            <template #title>
              {{ t('Complete Transfer?') }}
            </template>
            <template #activator="{ prompt }">
              <QBtn
                v-bind="buttonProps"
                icon="mdi-check-outline"
                :loading="progress.completing"
                :disable="store.movements.length === 0"
                @click="prompt"
              >
                {{ t('Complete') }}
              </QBtn>
            </template>
          </ConfirmsAction>

          <FixErrorsPrompt
            v-model:slide="slideWithErrorToFix"
            :error="errorMessages.get(store.movements[slideWithErrorToFix!]?.storable.id)"
            @fix="selectedMovementIndex = $event"
          />
        </template>
      </ButtonsRow>
    </Teleport>
    <BlurredInput
      skip-input-if-barcode
      speak-digits
      @barcode="scanPackRef.scan($event)"
    />
    <VirtualKeyboard :show-comma-key="amountIsFractional" />
  </div>
</template>

<script setup lang="ts">

import ConfirmsAction from '@/components/ConfirmsAction.vue';
import ButtonsRow from '@/components/Mobile/ButtonsRow.vue';
import ErrorsCarousel from '@/components/Mobile/ErrorsCarousel.vue';
import FixErrorsPrompt from '@/components/Mobile/FixErrorsPrompt.vue';
import useErrorHandling from '@/composables/useErrorHandling';
import useOmniInput from '@/composables/useOmniInput';
import useSpeaker from '@/composables/useSpeaker';
import type { Movement, ProductPack, Stock, StorableOrStorage, StorageUnit } from '@/graphql/types';
import { AskForWeightAndDimensionsOptionEnum } from '@/graphql/types';
import allocateAmountForPackInList from '@/helpers/allocateAmountForPackInList';
import getPackQuantityInBiggerPack from '@/helpers/getPackQuantityInBiggerPack';
import { areSame } from '@/helpers/graphql';
import productPackIsMissingWeightOrDimensions
  from '@/helpers/productPackIsMissingWeightOrDimensions';
import { speechOnProductPackScan } from '@/helpers/speechOnScan';
import ROUTES from '@/router/routeNames';
import useTransferProcessStore from '@/stores/transferProcess';
import ScanExpectedStorable from '@/views/Mobile/ScanExpectedStorable.vue';
import TransferChangeTargetStorage from '@/views/Mobile/Transfer/TransferChangeTargetStorage.vue';
import TransferProcessCarousel from '@/views/Mobile/Transfer/TransferProcessCarousel.vue';
import * as R from 'ramda';
import { last } from 'ramda';
import { computed, reactive, type Ref, ref, watch } from 'vue';
import { useI18n } from 'vue-i18n';
import { useRouter } from 'vue-router';
import type ScanPack from '@/views/Mobile/Selection/ScanPack.vue';
import type { CarouselItem } from '@/types/selection';
import BaseScanField from '@/components/Mobile/BaseScanField.vue';

const stocksCarousel: Ref<InstanceType<typeof TransferProcessCarousel>> = ref(null!);

const store = useTransferProcessStore();

const speaker = useSpeaker();

const { t } = useI18n();

// noinspection LocalVariableNamingConventionJS
const {
  primaryError,
  fillErrorsFromGraphQLError,
  clearErrors,
  errorsFor,
} = useErrorHandling();

const router = useRouter();

const progress = reactive({
  takingAll:    false,
  completing:   false,
  savingAmount: false,
});

const props = defineProps<{
  carouselInputDisabled: boolean;
}>();


const amountIsFractional = computed(() => store.selectedItem?.storageUnit.productPack.measurementUnit.isFractional ?? false);

const input = useOmniInput({
  replace: value => {
    // Не используем skip, т.к. нужна возможность сканирования ШК.
    if (!store.canChangeAmount) {
      return '';
    }

    return amountIsFractional.value
      ? value.replace(/,/g, '.').replace(/[^\d.]/g, '')
      : value.replace(/\D/g, '');
  },
});
const { BlurredInput, VirtualKeyboard, KeyboardToggleButton, value: inputValue, toggleCurrentKeyboard } = input;

watch(() => store.selectedItemIndex, () => {
  input.selectAll();
  clearErrors();
});

watch(() => store.selectedItem, item => {
  if (!item) {
    return;
  }
  input.reset(String(item.takenAmount));
}, { immediate: true });

watch(inputValue, value => {
  const numberValue = Number(value);
  if (!Number.isNaN(numberValue)) {
    changeScannedAmount(numberValue);
  }
});

const scanPackRef: Ref<InstanceType<typeof ScanPack>> = ref(null!);

async function changeScannedAmount(newAmount: number): Promise<void> {
  const diff = newAmount - store.selectedItem.takenAmount;

  if (diff === 0) {
    return;
  }

  if (diff > 0 && newAmount - store.selectedItem.plannedAmount > 0) {
    primaryError.value = t(
      'Excess product, only {needed} needed',
      { needed: store.selectedItem.plannedAmount },
    );
    speaker.speak(primaryError.value);

    return;
  }

  const existingMovement = store.itemMovement(store.selectedItem, store.transfer!.movements!);

  const { error } = existingMovement
    ? (await (newAmount === 0
      ? store.deleteMovements([existingMovement])
      : store.updateMovementAmount({ movement: existingMovement, amount: newAmount })))
    : (await store.moveStorageUnit(store.selectedItem.storageUnit, newAmount));

  if (error) {
    fillErrorsFromGraphQLError(error);
    speaker.speak(primaryError.value);
    return;
  }
  clearErrors();

  if (store.currentStorageFullySelected) {
    store.storageFrom = null;
    store.storageTo = null;
  }

  // Если меняли количество не для текущего товара,
  // то возможно потребуется добавить/удалить слайд в плане.
  if (store.selectedItemIndex !== store.currentItemIndex) {
    amountDirty.value = true;
  }

  // Если для текущего товара ввели нужное количество, переходим к следующему,
  // перед этим произнеся имя контейнера в случае кластерного отбора
  if (newAmount === store.currentItem.plannedAmount
      && store.selectedItemIndex === store.currentItemIndex
  ) {
    // По сути это то же самое, что отсканировать последнюю единицу товара,
    // поэтому используем тот же функционал
    store.adjustScannedItemPosition(store.carouselItems[store.currentItemIndex]);
  }
  store.selectedProductAmountWasChanged = true;

  if (store.commitAmountAfterChange) {
    store.commitAmountAfterChange = false;
    commitAmountIfDirty(store.selectedItem);
  }
}

const amountDirty = ref(false);

watch(() => store.selectedItem, (_, prevItem) => {
  commitAmountIfDirty(prevItem);
});

function commitAmountIfDirty(item: CarouselItem) {
  if (amountDirty.value) {
    store.handleProductAmountChange(item);
  }
  amountDirty.value = false;
}

watch(() => store.fullySelected, async fullySelected => {
  if (fullySelected && store.transfer?.task) {
    await complete();
  }
});

async function handleDeleteItem() {
  // В отличие от редактирования с клавиатуры, удаление должно отразиться на слайдах сразу же,
  // если удален был НЕ текущий слайд.
  store.commitAmountAfterChange = true;
  // Если удален был НЕ текущий слайд,
  // то в changeScannedAmount dirty станет true
  // и изменение будет отражено на слайдах (commitAmountIfDirty).
  input.reset('0');
}

const emit = defineEmits<{
  (e: 'cancel'): void;
  (e: 'show-history'): void;
  (e: 'show-task'): void;
  (e: 'update:carouselInputDisabled', disabled: boolean): void;
}>();

const task = computed(() => store.transfer!.task);

const selectedMovementIndex = ref<number>(0);
watch(selectedMovementIndex, () => {
  primaryError.value = '';
});

const errorMessages = reactive(new Map<string, string>());

const currentItemError = computed(() => {
  const storableId = store.movements[selectedMovementIndex.value]?.storable.id;

  return storableId ? errorMessages.get(storableId) : null;
});

const currentErrors = computed(() => [
  primaryError.value,
  currentItemError.value,
].filter(e => !!e));

const itemsWithErrorsIndexes = computed(
  () => store.movements
    .map((m, index) => errorMessages.get(m.storable.id) ? index : null)
    .filter(v => v !== null)
);

const lastEditedAmountIndex = ref<number | null>(null);

async function takeAll(): Promise<void> {
  progress.takingAll = true;

  const { error } = await store.takeAll();

  progress.takingAll = false;

  if (error) {
    fillErrorsFromGraphQLError(error);
    return;
  }

  selectedMovementIndex.value = Math.max(0, store.movements.length - 1);
}

async function handleSubmitAmount({ index, amount }: {
  index: number;
  amount: number;
}): Promise<void> {
  await convertStocksForMovement(store.movements[index], amount);
  await saveMovementAmount({ index, amount, speakOnError: false });
  lastEditedAmountIndex.value = null;
}

async function convertStocksForMovement(movement: Movement, neededAmount: number) {
  return pickStocksForMovement(movement, neededAmount, (stock, multiplier) => store
    .convertStockPack(stock.storageUnit, movement.storable.productPack, multiplier));
}

async function pickStocksForMovement(
  movement: Movement,
  neededAmount: number,
  onPick?: (stock: Omit<Stock, 'lockedAmount'>, multiplier: number) => unknown,
): Promise<number> {
  return await allocateAmountForPackInList(
    store.stocksInStorageFrom,
    movement.storable.productPack,
    neededAmount,
    s => s.storageUnit.productPack,
    s => {
      let availableAmount = s.amount;
      // Остатки в productItems без учета движений (как было до начала перемещения),
      // поэтому если берем остаток с другой упаковки, учитываем уже взятое количество.
      if (s.storageUnit.productPack.id !== movement.storable.productPack.id) {
        availableAmount -= store.savedMovements
          .filter(m => m.storable.id === s.storageUnit.id)
          .reduce((acc, m) => acc + m.amount, 0);
      }
      return availableAmount;
    },
    onPick,
  );
}

function handleStorableNotFoundError(): void {
  primaryError.value = t('Nothing found');
  speaker.speak(primaryError.value);
}

function clearMovementErrors() {
  if (!R.isEmpty(errorsFor('movements'))) {
    clearErrors();
  }
}

async function handleScan(entity: StorableOrStorage | ProductPack): Promise<void> {
  primaryError.value = '';
  clearMovementErrors();

  emit('update:carouselInputDisabled', true);

  if (areSame(entity, store.storageTo, R.prop('id'))) {
    if (itemsWithErrorsIndexes.value.length > 0) {
      selectedMovementIndex.value = itemsWithErrorsIndexes
        .value[itemsWithErrorsIndexes.value.length - 1]!;

      speaker.speak(currentItemError.value!);

      emit('update:carouselInputDisabled', false);

      return;
    }

    if (!task.value) {
      await complete();
    }
  } else {
    switch (entity.__typename) {
      case 'ProductPack':
        await scanProductPack(entity);
        break;
      case 'StorageUnit':
        await scanStorageUnit(entity);
        break;
    }
  }

  emit('update:carouselInputDisabled', false);
}

const selectedItem = computed(() => store.movements[selectedMovementIndex.value]);

async function scanProductPack(productPack: ProductPack) {
  const stock = store.availableStocksFrom.find(
    s => s.storageUnit.productPack.id === productPack.id,
  );

  if (stock && stock.amount >= 1) {
    return scanStorageUnit(stock.storageUnit);
  }

  try {
    const storageUnit = await getStorageUnitWithAnotherPack(productPack);
    if (storageUnit) {
      return scanStorageUnit(storageUnit);
    }
  } catch (error) {
    fillErrorsFromGraphQLError(error);
    speaker.speak(primaryError.value);
    return;
  }

  primaryError.value = t('Product {sku} {name} is missing', {
    sku:  productPack.product.sku,
    name: productPack.product.name,
  });
  speaker.speak(t('Product not found'));
}

async function getStorageUnitWithAnotherPack(productPack: ProductPack): Promise<StorageUnit | null> {
  const otherStorageUnit = store.availableStocksFrom.find(s => {
    const scannedAmount = getPackQuantityInBiggerPack(s.storageUnit.productPack, productPack)
      ?? 1;
    return s.storageUnit.productPack.product.id === productPack.product.id
      && scannedAmount <= s.amount;
  })?.storageUnit ?? null;

  if (!otherStorageUnit) {
    return null;
  }

  return store.convertStockPack(otherStorageUnit, productPack, 1);
}

function shouldNotifyAboutWeightAndDimensions(pack: ProductPack): boolean {
  return pack.product.accountingModel.askForWeightAndDimensions === AskForWeightAndDimensionsOptionEnum.ALWAYS;
}

async function scanStorageUnit(storageUnit: StorageUnit) {
  speaker.speak(speechOnProductPackScan(storageUnit.productPack));
  if (!store.currentItem && productPackIsMissingWeightOrDimensions(storageUnit.productPack)
  && shouldNotifyAboutWeightAndDimensions(storageUnit.productPack)) {
    speaker.speak(t('Specify Weight and Dimensions'));
  }

  const scannedItem = store.itemByStorageUnit(storageUnit);

  if (store.transfer?.task && !scannedItem) {
    primaryError.value = t('Wrong Product');
    speaker.speak(t('Wrong Product'));
    return;
  }

  await saveLastEditedAmount(false);

  const lastScanned = last(store.movements);

  // noinspection OverlyComplexBooleanExpressionJS
  if (lastScanned
      && lastScanned.storable.id === storageUnit.id
      && lastScanned.storageFrom!.id === store.storageFrom!.id
      && lastScanned.storageTo!.id === store.storageTo!.id
  ) {
    await saveMovementAmount({
      index: store.movements.findIndex(m => m.id === lastScanned.id),
      amount: lastScanned.amount + 1,
      speakOnError: true
    });

    selectedMovementIndex.value = store.movements.length - 1;
  } else {
    const { data, error } = await store.moveStorageUnit(storageUnit, 1);
    if (error) {
      fillErrorsFromGraphQLError(error);
      const movementError = errorsFor('movements')[0];
      if (movementError) {
        primaryError.value = movementError;
      }

      speaker.speak(movementError);
    } else {
      selectedMovementIndex.value = store.movements.findIndex(m => data!.movements[0].id === m.id);
      const savedMovement = store.savedMovements.find(m => m.id === selectedItem.value.id);
      if (savedMovement) {
        savedMovement.amount = selectedItem.value.amount;
      } else {
        store.savedMovements.push(selectedItem.value);
      }
    }
  }

  toggleCurrentKeyboard(false);

  if (scannedItem && !errorMessages.has(scannedItem.storageUnit.id)) {
    input.reset(String(store.selectedItem.takenAmount));
    store.adjustScannedItemPosition(scannedItem!);
  }

  if (task.value && store.currentItem?.takenAmount === store.currentItem?.plannedAmount) {
    if (store.fullySelected) {
      await complete();
    }
    if (store.currentStorageFullySelected) {
      store.storageFrom = null;
      store.storageTo   = null;
    }
    return;
  }

  if (!task.value) {
    stocksCarousel.value.resetInput();
  }
}

watch(() => store.movements, items => {
  if (selectedMovementIndex.value >= items.length) {
    selectedMovementIndex.value = items.length - 1;
  }
});

async function saveLastEditedAmount(speakOnError = true) {
  if (lastEditedAmountIndex.value !== null) {
    await convertStocksForMovement(store.movements[lastEditedAmountIndex.value], store.movements[lastEditedAmountIndex.value].amount);
    await saveMovementAmount({
      index:  lastEditedAmountIndex.value,
      amount: store.movements[lastEditedAmountIndex.value].amount,
      speakOnError,
    });
    lastEditedAmountIndex.value = null;
  }
}

const slideWithErrorToFix = ref<number | null>(null);

async function complete() {
  if (itemsWithErrorsIndexes.value.length > 0) {
    slideWithErrorToFix.value = itemsWithErrorsIndexes.value[0];
    speaker.speak(t('Fix the errors'));
    return;
  }

  progress.completing = true;

  await saveLastEditedAmount();

  store.availableStorages = [];
  store.savedMovements = [];

  store.clearStateForTransfer();
  await store.completeTransfer();

  speaker.speak(t('Completed'));

  progress.completing = false;

  await router.push({ name: ROUTES.TRANSFER_DASHBOARD });
}

watch(() => store.transfer?.locks[store.restockingLockIndex]?.place, (currPlace, prevPlace) => {
  if (currPlace && prevPlace && currPlace.id !== prevPlace.id) {
    store.$patch({
      storageTo:   null,
      storageFrom: null,
    });
  }
});

async function validateMovementAmount(movement: Movement, amount: number, speakOnError: boolean) {
  const amountLeft = await pickStocksForMovement(movement, amount);

  if (amountLeft > 0) {
    errorMessages.set(movement.storable.id, t(
      'Not enough product, available {stock}',
      { stock: amount - amountLeft },
    ));
    if (speakOnError) {
      speaker.speak(errorMessages.get(movement.storable.id)!);
    }
  } else {
    errorMessages.delete(movement.storable.id);
  }
}

async function updateSelectedItemAmount(amount: number) {
  clearMovementErrors();
  await validateMovementAmount(selectedItem.value, amount, true);

  selectedItem.value.amount = amount;
  lastEditedAmountIndex.value = selectedMovementIndex.value;

  if (errorMessages.size === 0) {
    clearErrors();
  }
}

async function saveMovementAmount({ index, amount, speakOnError }: {
  index: number;
  amount: number;
  speakOnError: boolean;
}): Promise<void> {
  progress.savingAmount = true;
  const movement = store.movements[index];
  await validateMovementAmount(movement, amount, speakOnError);
  if (amount === 0) {
    store.savedMovements.splice(store.savedMovements.findIndex(m => m.id === movement.id));
    await store.deleteMovements([movement]);
  } else if (!errorMessages.has(movement.storable.id)) {
    const { error } = await store.updateMovementAmount({
      movement: movement,
      amount,
    });
    if (error) {
      fillErrorsFromGraphQLError(error);
      primaryError.value = '';
      errorMessages.set(movement.storable.id, errorsFor('amount')[0]);
      speaker.speak(errorsFor('amount')[0]);
    } else {
      store.savedMovements.find(m => m.id === movement.id)!.amount = movement.amount;
    }
  } else {
    movement.amount = amount;
  }
  progress.savingAmount = false;
}

const isScanStorableDisabled = computed(
  () => progress.completing || progress.savingAmount || props.carouselInputDisabled
);

function handleCancel() {
  errorMessages.clear();

  emit('cancel');
}

const changeTargetStorageDialog = ref(false);

function handleTargetStorageChanged() {
  primaryError.value = '';
}

const scanField: Ref<InstanceType<typeof BaseScanField>> = ref(null!);

</script>

<i18n lang="yaml" src="../../../plugins/i18n/sharedMessages/scanning.yaml"></i18n>
<i18n lang="yaml" src="../../../plugins/i18n/sharedMessages/speaking.yaml"></i18n>

<i18n lang="yaml">
ru:
  Take All: Взять всё
  Taken: Взято
  Move: Переместить
  The Container Cell is different from the Storage From. Move to the Storage From?: >
    Ячейка контейнера отличается от текущей ячейки. Выполнить перемещение контейнера?
  Plan: План
  Product {sku} {name} is missing: Товар {sku} {name} не найден в ячейке
  Cancel Transfer?: Отменить перемещение?
  Complete Transfer?: Завершить перемещение?
  Change Storage: Изменить ячейку
  Fix the errors: Исправьте ошибки

en:
  Take All: Take All
  Taken: Taken
  Move: Move
  The Container Cell is different from the Storage From. Move to the Storage From?: >
    The Container Cell is different from the Storage From. Move to the Storage From?
  Plan: Plan
  Product {sku} {name} is missing: Product {sku} {name} is missing
  Cancel Transfer?: Cancel Transfer?
  Complete Transfer?: Complete Transfer?
  Change Storage: Change Storage
  Fix the errors: Fix the errors
</i18n>
