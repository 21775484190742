<template>
  <FilterableTable
    ref="itemsTable"
    :fields="task ? itemsHeaders : movementsHeaders"
    :rows="task ? task.items : props.transfer.movements"
    :row-is-deleted="i => (task ? i.storageUnit.productPack : i.storable.productPack).deletedAt !== null"
    :item-meets-search-string="task ? null :movementMeetsSearchString"
    :storage-prefix="'transferCard.' + props.transfer.id"
    show-line-numbers
    shrink
    no-loading-columns
  >
    <template #body-cell-sku="column">
      <BodyCellLink
        :column="column"
        :to="{ name: ROUTES.PRODUCTS_EDIT, params: { id: task
          ? column.row.storageUnit.productPack.product.id
          : column.row.storable.productPack.product.id } }"
        variant="link"
      />
    </template>
    <template
      v-if="canDeleteItems"
      #batch-actions="{ rows, refresh }"
    >
      <SimpleTableBatchActions :rows-count="rows.length">
        <QBtn
          class="bg-white text-black q-ml-sm"
          @click="deleteItems(rows); refresh()"
        >
          {{ t('Delete') }}
        </QBtn>
      </SimpleTableBatchActions>
    </template>
  </FilterableTable>
</template>

<script setup lang="ts">

import BodyCellLink from '@/components/BaseTable/BodyCellLink.vue';
import type {
  Movement,
  MutationDeleteOperationArgs,
  MutationDeleteTransferItemsArgs,
  Transfer,
  TransferItem,
} from '@/graphql/types';
import type { QTableColumn } from 'quasar';
import { useI18n } from 'vue-i18n';
import SimpleTableBatchActions from '@/views/SimpleTableBatchActions.vue';
import { computed, ref } from 'vue';
import { TransferStateEnum } from '@/graphql/types';
import { gql, useClientHandle } from '@urql/vue';
import itemAmount from '@/helpers/itemAmount';
import FilterableTable from '@/components/FilterableTable.vue';
import navigateBack from '@/helpers/navigateBack';
import movementMeetsSearchString from '@/helpers/movementMeetsSearchString';
import ROUTES from '@/router/routeNames';

const { t } = useI18n();

const { client: urql } = useClientHandle();

const props = defineProps<{
  transfer: Transfer;
}>();

const task = computed(() => props.transfer.task);

const itemsHeaders: QTableColumn<TransferItem>[] = [
  {
    label: t('SKU'),
    name:  'sku',
    field: i => i.storageUnit.productPack.product.sku,
    align: 'left',
  },
  {
    label: t('Name'),
    name:  'productPack.product.name',
    field: i => i.storageUnit.productPack.product.name,
    align: 'left',
  },
  {
    label: t('Origin Cell/Container'),
    name:  'storageFrom',
    field: i => i.storageFrom.name,
    align: 'left',
  },
  {
    label: t('Target Cell/Container'),
    name:  'storageTo',
    field: i => i.storageTo?.name,
    align: 'left',
  },
  {
    label: t('Amount'),
    name:  'itemAmount',
    field: i => itemAmount({ amount: i.plannedAmount, productPack: i.storageUnit.productPack }),
  },
  {
    label: t('Transferred'),
    name:  'transferredAmount',
    field: i => itemAmount({ amount: i.transferredAmount, productPack: i.storageUnit.productPack }),
  },
  {
    label: t('Not Transferred'),
    name: 'notTransferredAmount',
    field: i => itemAmount(
      { amount: i.plannedAmount - i.transferredAmount, productPack: i.storageUnit.productPack }
    ),
  },
];

const movementsHeaders: QTableColumn<Movement>[] = [
  {
    label: t('SKU'),
    name:  'sku',
    field: m => m.storable.productPack.product.sku,
    align: 'left',
  },
  {
    label: t('Name'),
    name:  'productPack.product.name',
    field: m => m.storable.productPack.product.name,
    align: 'left',
  },
  {
    label: t('Transferred'),
    name:  'transferredAmount',
    field: m => itemAmount({ amount: m.amount, productPack: m.storable.productPack }),
  },
  {
    label: t('Destination'),
    name:  'storageTo',
    field: m => m.storageTo?.name ?? '',
  },
];

const itemsTable = ref();

const canDeleteItems = computed(() => props.transfer.state === TransferStateEnum.CREATED);

async function deleteItems(items: TransferItem[]): Promise<void> {
  const { data } = await urql.mutation<{ transfer: Transfer }, MutationDeleteTransferItemsArgs>(
    gql`
      mutation DeleteRestockingItems($ids: [ID!]!, $transferId: ID!) {
        transfer: deleteTransferItems(ids: $ids, transferId: $transferId) {
          id
          task { items { id } }
        }
      }
    `,
    {
      ids:        items.map(i => i.id),
      transferId: props.transfer.id,
    },
  );

  itemsTable.value.refresh();

  const transferItems = data!.transfer.task?.items ?? [];

  if (transferItems.length === 0) {
    await urql.mutation<unknown, MutationDeleteOperationArgs>(
      gql`mutation DeleteTransferWithNoItems($id: ID!) { deleteOperation(id: $id) }`,
      { id: props.transfer.id! },
    );

    navigateBack({ name: 'Transfer' });
  }
}

</script>

<i18n lang="yaml">
ru:
  Origin Cell/Container: Исходная ячейка/контейнер
  Target Cell/Container: Конечная ячейка/контейнер
  Destination: Конечная ячейка
  Transferred: Перемещено
  Not Transferred: Не перемещено

en:
  Origin Cell/Container: Origin Cell/Container
  Target Cell/Container: Target Cell/Container
  Destination: Destination
  Transferred: Transferred
  Not Transferred: Not Transferred
</i18n>
