<template>
  <div class="q-pa-sm">
    <QSelect
      v-model="selectedLabelCountOption"
      :options="labelCountOptions"
      :label="t('Label count')"
    />
  </div>
  <div class="q-pa-sm">
    <VirtualKeyboardPolicyContext
      v-if="selectedLabelCountOption === t('Specific amount')"
      #="{ policy, toggle, shouldShowToggle }"
    >
      <QInput
        ref="countField"
        v-model.number="printCountForInput"
        type="number"
        step="1"
        min="1"
        :rules="[notEmptyRule, positiveIntegerOrNullRule]"
        :label="t('Count')"
        :virtualkeyboardpolicy="policy"
        autofocus
      >
        <template
          v-if="shouldShowToggle"
          #append
        >
          <VirtualKeyboardToggleBtn @toggle="countField.focus();" />
        </template>
      </QInput>
    </VirtualKeyboardPolicyContext>
    <QSelect
      v-else-if="productPack && selectedLabelCountOption === t('By supply')"
      v-model="selectedSupply"
      :loading="fetchingEntities"
      :options="suppliesOptions"
      :option-label="supplyOptionLabel"
      :label="t('Supply')"
    />
    <QSelect
      v-else-if="productPack && selectedLabelCountOption === t('By carrier orders')"
      v-model="selectedCarrierOrderCount"
      :loading="fetchingEntities"
      :options="carrierOrderCountOptions"
      :option-label="carrierPrintCountsOptions"
      :label="t('Shipment Order')"
      class="ellipsis"
    />
    <QSelect
      v-else-if="productPack"
      v-model="selectedOrder"
      :loading="fetchingEntities"
      :options="ordersOptions"
      :option-label="orderOptionLabel"
      :label="t('Shipment Order')"
      class="ellipsis"
    />
    <BlurredInput
      v-if="selectedLabelCountOption === t('Specific amount')"
    />
  </div>
</template>

<script setup lang="ts">

import useLocalizedFormatters, { FORMATS } from '@/composables/useLocalizedFormatters';
import type {
  Product, ProductBarcodesCountToPrint,
  ProductPack, QueryProductBarcodesCountsByCarriersForPrintArgs,
  QuerySuppliesByProductArgs,
  QuerySupplyArgs,
  CustomerOrder,
  ShipmentItem,
  Supply,
  SupplyItem,
} from '@/graphql/types';
import useDocumentsPrintingState from '@/views/useDocumentsPrintingState';
import { gql, useClientHandle } from '@urql/vue';
import * as R from 'ramda';
import { computed, onMounted, ref, watch } from 'vue';
import { useI18n } from 'vue-i18n';
import useValidationRules from '@/composables/useValidationRules';
import {
  VirtualKeyboardPolicyContext,
  VirtualKeyboardToggleBtn,
} from '@/composables/useVirtualKeyboardPolicy';
import useOmniInput from '@/composables/useOmniInput';

const { t } = useI18n();

const { formatDate } = useLocalizedFormatters();

const { client: urql } = useClientHandle();

const { notEmptyRule, positiveIntegerOrNullRule } = useValidationRules();

const countField = ref();

const supplyFragment = gql`
  fragment SupplyForProductPackPrint on Supply {
    id
    externalId
    createdAt
    items {
      id
      amount
      productPack {
        id
        product {
          id
        }
      }
    }
  }
`;

const orderFragment = gql`
  fragment CustomerOrderForProductPackPrint on CustomerOrder {
    id
    externalId
    createdAt
    carrier { id name }
    customer {
      id,
      name
    }
    items {
      id
      amount
      productPack {
        id
        product {
          id
        }
      }
    }
  }
`;

onMounted(async () => {
  loadSupplies();
  loadOrders();
  loadCarrierOrdersCount();
});

const emit = defineEmits<{
  (e: 'countChanged', count: number): void;
}>();

const fetchingEntities = ref(false);

const input = useOmniInput({
  skip: computed(() => !props.useBlurredInput),
  replace: value => value.replace(/\D/g, '')
});
const { BlurredInput, value: inputValue } = input;

watch(inputValue, value => {
  printCount.value = Number.parseInt(value);
});

async function loadSupplies() {
  fetchingEntities.value = true;

  const { data: suppliesData } = await urql.query<{ supplies: Supply[] }, QuerySuppliesByProductArgs>(
    gql`
        query GetSuppliesByProduct($productId: ID!) {
        supplies: suppliesByProduct(productId: $productId) {
            ...SupplyForProductPackPrint
        }
      }
      ${supplyFragment}
    `,
    { productId: props.productPack.product.id }
  );

  suppliesOptions.value = suppliesData!.supplies;

  if (!state.value.lastProductPackSupplyId) {
    fetchingEntities.value = false;
    return;
  }

  const { data: supplyData } = await urql.query<{ supply: Supply }, QuerySupplyArgs>(
    gql`
        query GetSupplyForPrintProductPack($id: ID!) {
        supply(id: $id) {
        ...SupplyForProductPackPrint
        } }
      ${supplyFragment}
    `,
    { id: state.value.lastProductPackSupplyId }
  );

  if (!suppliesOptions.value.some(s => s.id === supplyData!.supply.id)) {
    suppliesOptions.value.unshift(supplyData!.supply);
  }

  selectedSupply.value   = supplyData!.supply;
  fetchingEntities.value = false;
}

async function loadOrders() {
  fetchingEntities.value = true;

  const { data: ordersData } = await urql.query<{ orders: CustomerOrder[] }, QuerySuppliesByProductArgs>(
    gql`
        query GetCustomerOrdersByProduct($productId: ID!) {
        orders: customerOrdersForProductPackPrint(productId: $productId) {
          ...CustomerOrderForProductPackPrint
        }
      }
      ${orderFragment}
    `,
    { productId: props.productPack.product.id }
  );

  ordersOptions.value = ordersData!.orders;

  if (!state.value.lastProductPackOrderId) {
    fetchingEntities.value = false;
    return;
  }

  const { data: orderData } = await urql.query<{ customerOrder: CustomerOrder }, QuerySupplyArgs>(
    gql`
        query GetCustomerOrderForPrintProductPack($id: ID!) {
        customerOrder(id: $id) {
          ...CustomerOrderForProductPackPrint
        } }
      ${orderFragment}
    `,
    { id: state.value.lastProductPackOrderId }
  );

  if (!ordersOptions.value.some(s => s.id === orderData!.customerOrder.id)) {
    ordersOptions.value.unshift(orderData!.customerOrder);
  }

  selectedOrder.value   = orderData!.customerOrder;
  fetchingEntities.value = false;
}

const carrierOrderCountOptions  = ref<ProductBarcodesCountToPrint[]>([]);
const selectedCarrierOrderCount = ref<ProductBarcodesCountToPrint | null>(null);

watch(selectedCarrierOrderCount, (value: ProductBarcodesCountToPrint | null) => {
  if (!value) {
    return;
  }
  state.value.lastProductPackCarrierName = value.carrierName;
});

function carrierPrintCountsOptions(carrierPrintCounts: ProductBarcodesCountToPrint) {
  return `${carrierPrintCounts.carrierName ?? t('Not specified')} (${carrierPrintCounts.count})` ;
}

async function loadCarrierOrdersCount() {
  fetchingEntities.value = true;

  const { data: carrierPrintCountsData } = await urql.query<{ carrierPrintCounts: ProductBarcodesCountToPrint[] },
      QueryProductBarcodesCountsByCarriersForPrintArgs>(
        gql`
            query GetCarrierOrderPrintCounts($productId: ID!) {
            carrierPrintCounts: productBarcodesCountsByCarriersForPrint(productId: $productId) {
                carrierName
                count
            }
          }
        `,
        { productId: product.value.id }
      );

  carrierOrderCountOptions.value = carrierPrintCountsData!.carrierPrintCounts;

  if (!state.value.lastProductPackCarrierName) {
    fetchingEntities.value = false;
    return;
  }

  if (carrierOrderCountOptions.value.some(o => o.carrierName === state.value.lastProductPackCarrierName)) {
    selectedCarrierOrderCount.value   = carrierOrderCountOptions.value
      .find(o => o.carrierName === state.value.lastProductPackCarrierName)!;
  }

  fetchingEntities.value = false;
}

const props = defineProps<{
  productPack: ProductPack;
  useBlurredInput?: boolean;
}>();

const printCount = ref<number>(0);

const product = computed(() => props.productPack.product);

const state = useDocumentsPrintingState();

const labelCountOptions = ref<string[]>([
  t('Specific amount'), t('By supply'), t('By order'), t('By carrier orders')
]);

const selectedLabelCountOption = ref<string>(t('Specific amount'));

watch(selectedLabelCountOption, (value: string) => {
  state.value.lastProductPackLabelCountOption = value;
});

watch([selectedLabelCountOption, selectedCarrierOrderCount], ([selectedLabelCountOption, selectedCarrierOrderCount]) => {
  if (selectedLabelCountOption === t('By carrier orders') && selectedCarrierOrderCount) {
    printCount.value = selectedCarrierOrderCount.count!;
  }
});

const suppliesOptions = ref<Supply[]>([]);
const selectedSupply = ref<Supply | null>(null);

function supplyOptionLabel(supply: Supply) {
  return `${t('{id} dated {date}', {
    id: supply.externalId,
    date: formatDate(supply.createdAt, FORMATS.DATE),
  })} (${productAmount(supply, product.value)} ${props.productPack.measurementUnit.shortName})`;
}

watch(selectedSupply, (value: Supply | null) => {
  if (!value) {
    return;
  }
  state.value.lastProductPackSupplyId = value.id;
});

watch([selectedLabelCountOption, selectedSupply], ([selectedLabelCountOption, selectedSupply]) => {
  if (selectedLabelCountOption === t('By supply') && selectedSupply) {
    printCount.value = productAmount(selectedSupply, product.value);
  }
});

watch(printCount, (printCount) => {
  emit('countChanged', printCount);
});

const printCountForInput = computed({
  get: () => printCount.value,
  set: (value) => {
    printCount.value = value || 0;
  },
});

function productAmount(entity: Supply|CustomerOrder, product: Product): number {
  const amounts =  R.pipe(
    R.filter(si => (si as SupplyItem|ShipmentItem).productPack.product.id === product.id),
    R.map(si => (si as SupplyItem|ShipmentItem).amount)
  );

  return R.sum(amounts(entity.items));
}

const ordersOptions = ref<CustomerOrder[]>([]);
const selectedOrder = ref<CustomerOrder | null>(null);

watch(selectedOrder, (value: CustomerOrder | null) => {
  if (!value) {
    return;
  }
  state.value.lastProductPackOrderId = value.id;
});

watch([selectedLabelCountOption, selectedOrder], ([selectedLabelCountOption, selectedOrder]) => {
  if (selectedLabelCountOption === t('By order') && selectedOrder) {
    printCount.value = productAmount(selectedOrder, product.value);
  }
});

function orderOptionLabel(order: CustomerOrder) {
  return `${order.externalId} ${order.carrier?.name ?? ''} ${order.customer?.name ?? ''}`
  + `(${productAmount(order, product.value)} ${props.productPack.measurementUnit.shortName})`;
}

selectedLabelCountOption.value = state.value.lastProductPackLabelCountOption ?? t('Specific amount');


</script>

<i18n lang="yaml" src="../../plugins/i18n/sharedMessages/printing.yaml"></i18n>

<i18n lang="yaml">
ru:
  Primary: Основной
  Select barcode type: Выберите тип ШК
en:
  Primary: Primary
  Select barcode type: Select barcode type
</i18n>
